import { Reducer, AnyAction } from "redux";
import * as actionTypes from "../../actions/auth-actions/types"

const INITIAL_STATE = {
  player: null,
  isLoading: false,
  isRequesting: false,
  isAuthenticated: false,
};

const authReducer = (
  state: PlayerState = INITIAL_STATE,
  action: PlayerAction
): PlayerState => {
  switch (action.type) {
    case actionTypes.AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        player: action?.payload,
        isAuthenticated: true,
      }
    case actionTypes.AUTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        player: null,
        isAuthenticated: false
      }

    case actionTypes.REGISTER_REQUEST:
      localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-token" : "token")
      return {
        ...state,
        isRequesting: true,
      }
    case actionTypes.REGISTER_SUCCESS:
      localStorage.setItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-token" : "token", action.payload?.token)
      return {
        ...state,
        isRequesting: false,
        player: action.payload?.player,
        isAuthenticated: true,
      }
    case actionTypes.REGISTER_FAILURE:
      return {
        ...state,
        isRequesting: false,
      }

    case actionTypes.LOGOUT_SUCCESS:
      localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-token" : "token")
      return {
        ...state,
        isRequesting: false,
        player: null,
        isAuthenticated: false
      }
    case actionTypes.LOGOUT_FAILURE:
      return {
        ...state,
        isRequesting: false,
      }
  }
  return state
};

export default authReducer;