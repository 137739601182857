import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import Logo from "../../../svg-components/Logo";
import "./not-found-page.scss";
import { FrownOutlined } from '@ant-design/icons';
import { Result } from "antd";

const NotFound = (props: RouteComponentProps) => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_WEBSITE_NAME} - 404 Not Found`
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute("content", "Description");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute("href", process.env.REACT_APP_SITE_URL + "/404" || '');
    }
  }, [])
  return (
    <div className={"not-found-page"}>
      <div className="logo">
        <Link to={'/'}><Logo color="black" /></Link>
      </div>
      <Result
        status="404"
        title={<Trans>Page Not Found</Trans>}
        subTitle={<Trans>Page Not Found</Trans>}
        extra={<Link to={'/'}><Trans>Back home</Trans></Link>}
      />
    </div>
  );
};

export default NotFound;