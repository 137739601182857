import React from 'react';
import logoImg from '../assets/images/logo quiz amen bank.png'

export type LogoProps = {
    color?: string
}

const Logo = (props: LogoProps) => {
    return (
        <img src={logoImg} style={{ width: "100%" }} />
    );
}
export default Logo;