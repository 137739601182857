import * as actionTypes from './types'
import * as actionServices from './services'
import { message } from 'antd';
import i18n from '../../../i18n';
import { AxiosError } from 'axios';

export function getAuthPlayer() {
    return async (dispatch: PlayerDispatchType) => {
        dispatch({
            type: actionTypes.AUTH_REQUEST,
        });
        try {
            const { data } = await actionServices.authRequest();
            dispatch({
                type: actionTypes.AUTH_SUCCESS,
                payload: data.data
            });
            return true
        } catch (e) {
            dispatch({
                type: actionTypes.AUTH_FAILURE,
            });
            console.log(e);
            return false
        }
    };
}

export function register(infos: PlayerData) {
    return async (dispatch: PlayerDispatchType) => {
        dispatch({
            type: actionTypes.REGISTER_REQUEST,
        });
        try {
            let { data } = await actionServices.registerRequest(infos);
            dispatch({
                type: actionTypes.REGISTER_SUCCESS,
                payload: data.data
            });
            // message.success(i18n.t('Bienvenue !'))
        } catch (e: any) {
            dispatch({
                type: actionTypes.REGISTER_FAILURE
            });
            if(e?.response?.status == 401){
                message.error(i18n.t("Email or password incorrect !"))
            }else{
                message.error(i18n.t('Something went wrong! Please try again later.'))
            }
            console.log(e.response);
        }
    };
}

export function logout() {
    return async (dispatch: PlayerDispatchType) => {
        dispatch({
            type: actionTypes.LOGOUT_REQUEST,
        });
        try {
            // let response = await actionServices.logoutRequest();
            dispatch({
                type: actionTypes.LOGOUT_SUCCESS,
            });
            message.success(i18n.t('Goodbye !'))
        } catch (e: any) {
            dispatch({
                type: actionTypes.LOGOUT_FAILURE
            });
            console.log(e);
            message.error(i18n.t('Something went wrong! Please try again later.'))
        }
    };
}