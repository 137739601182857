import i18n, { LANGUAGES } from "../../../i18n";
import * as actionTypes from "../../actions/ui-actions/types"
import { LANG, UIAction, UIState } from "./types";

var defaultLang: LANG | string | null  = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-lang" : "lang");

const INITIAL_STATE = {
  lang: defaultLang ?? LANGUAGES.en,
};

const uiReducer = (
  state: UIState = INITIAL_STATE,
  action: UIAction
): UIState => {
  switch (action.type) {
    case actionTypes.CHANGE_LANG:
      i18n.changeLanguage(action.payload)
      localStorage.setItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-lang" : "lang", action.payload)
      return {
        ...state,
        lang: action.payload,
      }

  }
  return state
};

export default uiReducer;