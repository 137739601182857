import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import Logo from "../../../svg-components/Logo";
import "./home-page.scss";
import { Row, Col, Button, Checkbox, Form, Input, Card, Space, DatePicker, Image } from 'antd';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { RootState } from "../../../store/store";
import sideImage from '../../../assets/images/sideImage.png'
import playAndWinImg from '../../../assets/images/answerWinner.png'
import playImg from '../../../assets/images/btncherk.png'
import { CheckboxChangeEvent } from "antd/es/checkbox";
import $ from 'jquery';
import {
  UserOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { QuizContext } from "../../../contexts/QuizContext";

const TERMS_AND_CONDITIONS_URL = "https://app.amenbankquiz.com/docs/Reglement-jeu-quiz-AMEN-BANK.pdf";

const HomePage = (props: RouteComponentProps) => {
  const { register } = useContext(QuizContext)
  const { t, i18n } = useTranslation();
  const { isRequesting } = useAppSelector(
    (state: RootState) => state.playerReducer
  );
  const [hasAccepted, setHasAccepted] = useState(false)
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    document.title = `${process.env.REACT_APP_WEBSITE_NAME} - Register`
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute("content", "Description");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute("href", process.env.REACT_APP_SITE_URL + "/register" || '');
    }
  }, [])
  const onFinish = async (values: PlayerData) => {
    values.date_of_birth = dayjs(values.date_of_birth).format("YYYY-MM-DD")
    await register?.(values)
  };
  useMemo(() => {
    $("#start-btn-wrapper").removeClass(["slide-in-fwd-center", "slide-out-bck-center"])
    if (checked) {
      $("#start-btn-wrapper").addClass("slide-in-fwd-center")
    } else {
      $("#start-btn-wrapper").addClass("slide-out-bck-center")
    }
  }, [checked])
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days after today and today
    return current && current > dayjs().endOf('day');
  };
  return (
    <div className={"home-page"}>
      <div className="logo">
        <Link to={'/'}><Logo color="black" /></Link>
      </div>
      <Row style={{ minHeight: "100%", display: "flex", alignItems: "center" }} className="content">
        <Col xs={24} md={12} style={{ zIndex: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className="start-wrapper">
            <img src={playAndWinImg} alt="Jaweb w arba7" className="play-and-win-img" />
            <div style={{ minHeight: "90px", textAlign: "center", visibility: checked ? "visible" : "hidden" }} id="start-btn-wrapper">
              <Link to="/register" id="start-btn" type="ghost" onClick={() => setHasAccepted(true)}>
                <img src={playImg} alt="Start" style={{ width: "70%", margin: "1rem" }} />
              </Link>
            </div>
            <p className="terms" style={{ marginBottom: '20px', paddingBottom: "50px", textAlign: "center", color: "#fff", fontSize: "18px", alignSelf: "center" }}>
              <Checkbox checked={checked} onChange={(e: CheckboxChangeEvent) => setChecked(e.target.checked)} style={{ color: "#fff", marginTop: "2rem", fontSize: "18px" }}>
                J'ai lu et j'accepte <a className="link-terms" target={"_blank"} href={TERMS_AND_CONDITIONS_URL}>le règlement du jeu.</a>
              </Checkbox>
            </p>
          </div>
        </Col>
        <Col xs={24} md={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", alignSelf: "center", overflow: "hidden" }}>
          <img src={sideImage} className="bottom-left-img" style={{ display: "block", padding: "1rem" }} alt="image" />
        </Col>
      </Row>

    </div>
  );
};

export default HomePage;
