import React, { useContext, useEffect } from "react";
import { Route, withRouter, Switch, Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import "./sass/app.scss";
import Routes from './routes'
import { QuizProvider } from "./contexts/QuizContext";

const App = () => {
  return (
      <QuizProvider>
        <Routes />
      </QuizProvider>
  );
};

export default withRouter(App);
