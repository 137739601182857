import { combineReducers } from 'redux'
import playerReducer from "./player-reducer/reducer"
import quizReducer from "./quiz-reducer/reducer"
import uiReducer from "./ui-reducer/reducer"

const RootReducer = combineReducers({
    playerReducer,
    quizReducer,
    uiReducer,
});

export default RootReducer;