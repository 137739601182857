import { Checkbox, Col, Radio, RadioChangeEvent, Row } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useState } from "react";

const ORDERS = ["A", "B", "C", "D", "E", "F", "G"]

const CheckboxQuestionOptions: React.FC<CheckboxQuestionOptionsProps> = (props) => {
    const onChange = (checkedValues: CheckboxValueType[]) => {
        props?.onChange(checkedValues)
    };
    return (
        <Checkbox.Group onChange={onChange}>
            {props?.options?.map((option, i) => (
                <div className="option-wrapper">
                    <Checkbox key={i} className="text-primary" value={option?.id}>
                        <Row style={{width: "100%"}}>
                            <Col span={2}>
                                <span className="order">{ORDERS[i]}</span>
                            </Col>
                            <Col span={22}>
                                <span className="option-content">{option?.content}</span>
                            </Col>
                        </Row>
                    </Checkbox>
                </div>
            ))}
        </Checkbox.Group>
    );
}
export default CheckboxQuestionOptions;