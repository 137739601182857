import { Button, Card, Col, DatePicker, Form, Input, Row } from "antd"
import { Link, RouteComponentProps } from "react-router-dom"
import Logo from "../../svg-components/Logo"
import sideImage from '../../assets/images/win.png'
import gift from "../../assets/images/gift.png"
import "./win-page.scss"
import btnPartage from "../../assets/images/btnshare.png"
import {
    UserOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import { useCallback, useContext } from "react"
import { QuizContext } from "../../contexts/QuizContext"
import { useAppSelector } from "../../hooks/reduxHooks"
import { RootState } from "../../store/store"

const WinPage = (props: RouteComponentProps) => {
    const { player, signOut } = useContext(QuizContext);
    const { quiz }: QuizState = useAppSelector(
        (state: RootState) => state.quizReducer
    );
    const logout = async () => {
        await signOut?.()
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + process.env.REACT_APP_SITE_URL, "_blank")
    }
    return (<>
        <div className={"win-page"}>
            <div className="logo">
                <Link to={'/'}><Logo color="black" /></Link>
            </div>
            <Row style={{ height: "100%", minHeight: "100%" }} className="content">
                <Col xs={24} md={12}>
                    <Col className="msg">
                        <p style={{ color: "#fff", fontWeight: "700", fontSize: "60px" }}>Bonne réponse !</p>
                        <span>Bien joué! Vous accédez au tirage au sort pour tenter de gagner <span style={{
                            fontWeight: "700", display: "inline-block"
                        }}>{quiz?.gift}</span></span>
                        <div style={{ minHeight: "100px", textAlign: "center" }} id="start-btn-wrapper">
                            <Button id="shareBtn" onClick={logout} >
                                <img src={btnPartage} alt="Start" style={{ margin: "2.5rem", width: "70%" }} />
                            </Button>
                        </div>
                    </Col>
                </Col>
                <Col xs={24} md={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", alignSelf: "center" }}>
                    <img src={sideImage} className="bottom-left-img" style={{ display: "block", width: "100%" }} alt="image" />
                </Col>
            </Row>

        </div>
    </>)
}
export default WinPage