import axiosInstance from '../../../config/axios-instance'

export function authRequest() {
    return axiosInstance({
        method: "get",
        url: "/auth/player",
    });
}

export function registerRequest(data: PlayerData) {
    return axiosInstance({
        method: "post",
        url: "/player",
        data: data,
    });
}

export function logoutRequest() {
    return axiosInstance({
        method: "get",
        url: "/logout",
    });
}