import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import Logo from "../../../svg-components/Logo";
import "./register-page.scss";
import { Row, Col, Button, Checkbox, Form, Input, Card, Space, DatePicker, Image } from 'antd';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { RootState } from "../../../store/store";
import sideImage from '../../../assets/images/side.png'
import playAndWinImg from '../../../assets/images/answerWinner.png'
import playImg from '../../../assets/images/btncherk.png'
import { CheckboxChangeEvent } from "antd/es/checkbox";
import $ from 'jquery';
import {
  UserOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { QuizContext } from "../../../contexts/QuizContext";

const TERMS_AND_CONDITIONS_URL = "https://app.amenbankquiz.com/docs/Reglement-jeu-quiz-AMEN-BANK.pdf";

const RegisterPage = (props: RouteComponentProps) => {
  const { register } = useContext(QuizContext)
  const { t, i18n } = useTranslation();
  const { isRequesting } = useAppSelector(
    (state: RootState) => state.playerReducer
  );
  const [hasAccepted, setHasAccepted] = useState(false)
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    document.title = `${process.env.REACT_APP_WEBSITE_NAME} - Register`
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute("content", "Description");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute("href", process.env.REACT_APP_SITE_URL + "/register" || '');
    }
  }, [])
  const onFinish = async (values: PlayerData) => {
    values.date_of_birth = dayjs(values.date_of_birth).format("YYYY-MM-DD")
    await register?.(values)
  };
  useMemo(() => {
    $("#start-btn-wrapper").removeClass(["slide-in-fwd-center", "slide-out-bck-center"])
    if (checked) {
      $("#start-btn-wrapper").addClass("slide-in-fwd-center")
    } else {
      $("#start-btn-wrapper").addClass("slide-out-bck-center")
    }
  }, [checked])
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days after today and today
    return current && current > dayjs().endOf('day');
  };
  return (<>
    <div className={"register-page"}>
      <div className="logo">
        <Link to={'/'}><Logo color="black" /></Link>
      </div>
      <Row className="register-form content" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Col xs={24} md={12} style={{ zIndex: 0, display: "flex", justifyContent: "center", alignItems: "center" }} className="left">
          <div className="form-wrapper" >
            <div className="user-icon-wrapper">
              <UserOutlined />
            </div>
            <Card title={<h2 className="title">S'inscrire</h2>} bordered className="card" style={{ backgroundColor: "#fff" }}>
              <Form
                name="register"
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="name"
                  validateStatus=""
                  rules={[{ required: true, message: 'Veuillez saisir votre nom et prénom !' }]}
                  htmlFor="name"
                >
                  <Input name="name" placeholder="Nom et Prénom*" />
                </Form.Item>
                <Form.Item
                  name="date_of_birth"
                  rules={[{ required: true, message: 'Veuillez saisir votre date de naissance !' }]}
                  htmlFor="date_of_birth"
                >
                  <DatePicker style={{ width: '100%' }} format={'DD/MM/YYYY'} placeholder="Date de naissance*" disabledDate={disabledDate} />
                </Form.Item>
                <Form.Item
                  name="phone"
                  validateStatus=""
                  htmlFor="phone"
                  rules={[
                    { required: true, message: 'Veuillez saisir votre numéro de téléphone !' },
                    { max: 8, message: 'Veuillez saisir votre numéro de téléphone valide !' },
                    { min: 8, message: 'Veuillez saisir votre numéro de téléphone valide !' },
                  ]}
                >
                  <Input name="phone" placeholder="Numéro GSM*" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Veuillez saisir votre adresse E-mail !'
                    },
                    {
                      type: 'email',
                      message: "Veuillez saisir une adresse E-mail valide !",
                    },

                  ]}
                  className="form-item"
                >
                  <Input name="email" placeholder="Email*" />
                </Form.Item>

                <p className="text-primary" style={{ textAlign: "center" }}>*Tous les champs sont obligatoires</p>
                <div className=" btn-register-wrapper">
                  <Button type="primary" className="btn-primary btn-register" htmlType="submit" loading={isRequesting}>
                    Suivant
                  </Button>
                </div>
              </Form>
            </Card>
          </div>
        </Col>
        <Col xs={24} md={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", alignSelf: "center" }}>
          <img src={sideImage} className="bottom-left-img" style={{ display: "block" }} alt="image" />
        </Col>
      </Row>


    </div>
  </>)
}
export default RegisterPage;