import React, { useContext } from "react";
import { Route, withRouter, Switch, Redirect, RouteComponentProps, RouteChildrenProps, RouteProps, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import MainLayout from "../layouts/MainLayout";
import WideLayout from "../layouts/WideLayout";
import NotFound from "../pages/error-pages/not-found-page/NotFound";
import QuizPage from "../pages/quiz-page/QuizPage";
import { QuizConsumer } from "../contexts/QuizContext";
import WinPage from "../pages/win-page/winPage";
import LosePage from "../pages/lose-page/lose-page";
import HomePage from "../pages/auth-pages/register-page/HomePage";
import RegisterPage from "../pages/auth-pages/register-page/RegisterPage";

export default () => {
    let location = useLocation();
    return (
        <TransitionGroup>
            <CSSTransition
                key={location.pathname}
                classNames="fade"
                timeout={300}
            >
                <Switch>
                    <SwitcherRoute
                        exact
                        path="/"
                    />
                    <PublicRoute
                        exact
                        path={'/home'}
                        component={HomePage}
                    />
                    <PublicRoute
                        exact
                        path={'/register'}
                        component={RegisterPage}
                    />
                    <ProtectedRoute
                        exact
                        path="/quiz"
                        component={QuizPage}
                    />

                    <ProtectedResultRoute
                        exact
                        path={'/lose'}
                        component={LosePage}
                    />
                    <ProtectedResultRoute
                        exact
                        path={'/win'}
                        component={WinPage}
                    />
                    <Route
                        exact
                        path={'/404'}
                        render={
                            (props: RouteComponentProps) =>
                                <WideLayout {...props}>
                                    <NotFound {...props} />
                                </WideLayout>

                        }
                    />
                    <Route
                        path={'*'}
                        render={
                            (props: RouteComponentProps) =>
                                <WideLayout {...props}>
                                    <NotFound {...props} />
                                </WideLayout>

                        }
                    />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
};

const SwitcherRoute = ({ component: Component, ...rest }: any) => (
    <QuizConsumer>
        {({ isAuthenticated }) => (
            <Route
                render={
                    (props) => {
                        return isAuthenticated ? (
                            <Redirect
                                to={props.location?.state?.from ? {
                                    pathname: props.location?.state?.from.pathname,
                                    search: props.location?.state?.from.search,
                                    state: props.location?.state?.from.state
                                } : "/quiz"}
                            />

                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/home",
                                    state: { from: props.location?.state?.from ? props.location?.state?.from : props.location },
                                }}
                            />
                        )
                    }
                }
                {...rest}
            />
        )}
    </QuizConsumer>
);

const ProtectedRoute = ({ component: Component, ...rest }: any) => (
    <QuizConsumer>
        {({ isAuthenticated }) => (
            <Route
                render={
                    (props) => {
                        return isAuthenticated ? (
                            <MainLayout {...props}>
                                <Component {...props} />
                            </MainLayout>
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/home",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        )
                    }
                }
                {...rest}
            />
        )}
    </QuizConsumer>
);
const ProtectedResultRoute = ({ component: Component, ...rest }: any) => (
    <QuizConsumer>
        {({ isAuthenticated, has_completed_quiz }) => (
            <Route
                render={
                    (props) => {
                        return isAuthenticated && has_completed_quiz ? (
                            <MainLayout {...props}>
                                <Component {...props} />
                            </MainLayout>
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/quiz",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        )
                    }
                }
                {...rest}
            />
        )}
    </QuizConsumer>
);

const PublicRoute = ({ component: Component, ...rest }: any) => (
    <QuizConsumer>
        {({ isAuthenticated, isLoading }) => (
            <Route
                render={
                    (props) => {
                        return isAuthenticated
                            ? <Redirect
                                to={props.location?.state?.from ? {
                                    pathname: props.location?.state?.from.pathname,
                                    search: props.location?.state?.from.search,
                                    state: props.location?.state?.from.state
                                } : "/quiz"}
                            />
                            : (
                                <MainLayout {...props}>
                                    <Component {...props} />
                                </MainLayout>
                            )
                    }
                }
                {...rest}
            />
        )}
    </QuizConsumer>
);