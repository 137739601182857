import * as actionTypes from './types'
import * as actionServices from './services'
import { message } from 'antd';
import i18n from '../../../i18n';
import { AxiosError } from 'axios';

export function getActiveQuiz() {
    return async (dispatch: PlayerDispatchType) => {
        dispatch({
            type: actionTypes.GET_ACTIVE_QUIZ_REQUEST,
        });
        try {
            const { data } = await actionServices.getActiveQuizRequest();
            dispatch({
                type: actionTypes.GET_ACTIVE_QUIZ_SUCCESS,
                payload: data.data
            });
            return true
        } catch (e) {
            dispatch({
                type: actionTypes.GET_ACTIVE_QUIZ_FAILURE,
            });
            console.log(e);
            return false
        }
    };
}
export function submitQuizAnswers(quiz_id: number, answers: any) {
    return async (dispatch: PlayerDispatchType) => {
        dispatch({
            type: actionTypes.SUBMIT_QUIZ_REQUEST,
        });
        try {
            const { data } = await actionServices.submitQuizAnswersRequest(quiz_id, answers);
            dispatch({
                type: actionTypes.SUBMIT_QUIZ_SUCCESS,
                payload: data.data
            });
            return true
        } catch (e) {
            dispatch({
                type: actionTypes.SUBMIT_QUIZ_SUCCESS,
            });
            console.log(e);
            return false
        }
    };
}