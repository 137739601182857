import React, { createContext, useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { getAuthPlayer, logout, register } from "../store/actions/auth-actions/actions";
import { RootState } from "../store/store";
import BounceLoader from "react-spinners/BounceLoader";
import Logo from "../svg-components/Logo";
import { notification } from "antd";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import { RESET_QUIZ } from "../store/actions/quiz-actions/types";

export type QuizContextProps = {
    player: IPlayer | null
    isLoading: boolean;
    has_completed_quiz: boolean;
    is_correct: boolean;
    isAuthenticated: null | boolean;
    register: (infos: PlayerData) => Promise<void>
    signOut: () => Promise<void>
};

const QuizContext = createContext<Partial<QuizContextProps>>({});


interface Props {
    children: React.ReactNode;
}

const QuizProvider = (props: Props) => {
    const history = useHistory();
    const dispatch = useAppDispatch()
    const { isLoading, isAuthenticated, player }: PlayerState = useAppSelector(
        (state: RootState) => state.playerReducer
    );
    const { isRequesting, quiz, questions, has_completed_quiz, is_correct }: QuizState = useAppSelector(
        (state: RootState) => state.quizReducer
      );
    useEffect(() => {
        const authenticate = async () => {
            try {
                var token = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-token" : "token");
                if (token) {
                    await dispatch(getAuthPlayer());
                }
            } catch (error) {
                console.warn(error);
            }
        }
        authenticate()
    }, []);
    const registerPlayer = useCallback(async (infos: PlayerData) => {
        if (isAuthenticated)
            return
        await dispatch(register(infos))
    }, [isAuthenticated])
    const signOut = useCallback(async () => {
        if (!isAuthenticated)
            return
        await dispatch(logout())
        await dispatch({type: RESET_QUIZ})
    }, [isAuthenticated])

    return (
        <QuizContext.Provider value={{has_completed_quiz:has_completed_quiz,is_correct:is_correct, isLoading: isLoading, isAuthenticated: isAuthenticated, player: player, register: registerPlayer, signOut: signOut }}>
            {isLoading ? (
                <div className="app-loading-container">
                    <div className="logo">
                        <Logo color="black" />
                    </div>
                    <BounceLoader color={"#6dbbe3"} loading size={45} />
                </div>
            ) : props.children}
        </QuizContext.Provider>
    );
};
const QuizConsumer = QuizContext.Consumer
export { QuizContext, QuizProvider, QuizConsumer };