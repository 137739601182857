import React, { useEffect } from "react";
import * as ReactDOM from 'react-dom';
import App from "./App";
import store, { RootState } from "./store/store";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter as Router, RouteProps } from "react-router-dom";
import './i18n';
// RCE CSS
import ScrollToTop from "./tools/ScrollToTop";
import BounceLoader from "react-spinners/BounceLoader";
import { useAppSelector } from "./hooks/reduxHooks";

// Import Socket Client
// import './config/laravel-echo-setup.ts';

if (document.getElementById("root")) {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </Provider >,
    document.getElementById("root")
  );
}
