//Get Active Quiz
export const GET_ACTIVE_QUIZ_REQUEST = "GET_ACTIVE_QUIZ_REQUEST"
export const GET_ACTIVE_QUIZ_SUCCESS = "GET_ACTIVE_QUIZ_SUCCESS"
export const GET_ACTIVE_QUIZ_FAILURE = "GET_ACTIVE_QUIZ_FAILURE"

//Submit Active Quiz
export const SUBMIT_QUIZ_REQUEST = "SUBMIT_QUIZ_REQUEST"
export const SUBMIT_QUIZ_SUCCESS = "SUBMIT_QUIZ_SUCCESS"
export const SUBMIT_QUIZ_FAILURE = "SUBMIT_QUIZ_FAILURE"

//RESET
export const RESET_QUIZ = "RESET_QUIZ"