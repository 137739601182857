import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import "./quiz-page.scss";
import { EnvironmentOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic, Typography, Space, Skeleton, message, Alert, Modal, Button } from 'antd';
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Trans } from "react-i18next";
import { getActiveQuiz, submitQuizAnswers } from "../../store/actions/quiz-actions/actions";
import { QuizContext } from "../../contexts/QuizContext";
import { gsap } from "gsap";
import QuestionCard from "../../components/quiz-components/question-card/QuestionCard";
import Logo from "../../svg-components/Logo";
import trophiesImg from '../../assets/images/quiz.png'
import { RESET_QUIZ } from "../../store/actions/quiz-actions/types";

const QuizPage = (props: RouteComponentProps) => {
  const dispatch = useAppDispatch();
  const { player, signOut } = useContext(QuizContext);
  const { isRequesting, quiz, questions, isLoading, has_completed_quiz, is_correct }: QuizState = useAppSelector(
    (state: RootState) => state.quizReducer
  );
  const history = useHistory()
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(0);
  useEffect(() => {
    if (has_completed_quiz) setOpen(true)
  }, [has_completed_quiz])

  const getNext = useCallback(async () => {
    if (!quiz)
      return
    const next = key + 1
    const nextQuestion = document.getElementById(`question-${next}`);
    if (nextQuestion && next < questions.length) {
      setKey(next)
      gsap.fromTo(`#question-${next}`, { x: '100%' }, {
        x: 0,
      })
    }
  }, [player, key, quiz, questions])
  const onSubmit = useCallback(async (data) => {
    if (!quiz)
      return
    const res = await dispatch(submitQuizAnswers(quiz?.id, data))
    if (res == true) {
      await getNext()
    } else {
      message.error('Un problème est survenu ! Veuillez réessayer plus tard.')
    }
  }, [quiz, key])
  const onFinishQuestion = useCallback(async (question_id: number, options: any[]) => {
    await onSubmit({ question_id, answers: typeof (options) == "string" ? [options] : options })
  }, [key, quiz, questions]);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_WEBSITE_NAME} - Quiz`
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute("content", "Description");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute("href", process.env.REACT_APP_SITE_URL + "/app/quiz" || '');
    }
  }, [])
  const fetchQuiz = useCallback(async () => {
    await dispatch({ type: RESET_QUIZ })

    await dispatch(getActiveQuiz())
  }, [])
  useEffect(() => {

    fetchQuiz().then().catch(e => console.log(e))
  }, [])
  const handleOk = async () => {
    await signOut?.()
    setOpen(false);
  };

  useEffect(() => {
    if (is_correct && has_completed_quiz && open) {
      history.push("/win")

    }
    if (!is_correct && has_completed_quiz && open) {
      history.push("/lose")


    }
  }, [is_correct, has_completed_quiz, open])
  return (
    <div className={"quiz-page"}>
      <div className="logo">
        <Link to={'/'}><Logo color="black" /></Link>
      </div>
      <Row style={{ paddingBottom: 100 }} className="content">
        <Col xs={24} md={12} className="quiz-col" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {isLoading ? (
            <Skeleton active />
          ) :
            questions?.length > 0 ? (
              <section
                className="questions mb-3"
              >
                {/* <Alert message="Sélectionner la (les) propositions(s) juste (s)" type="info" showIcon style={{ marginBottom: "1rem" }} /> */}
                {questions?.map((question, i) =>
                  <article id={`question-${i}`} data-index={i} title="Question" key={i} style={{ display: key == i ? "block" : "none" }}>
                    <div className="question-wrapper">
                      <QuestionCard
                        index={i}
                        question={question}
                        options={question.options}
                        onSubmit={onFinishQuestion}
                      />
                    </div>
                  </article>
                )}
              </section>
            ) : (
              <div className="text-center">
                <Typography.Text type="secondary" style={{ textAlign: "center", color: "#fff", fontSize: "24px" }} >La prochaine question sera affichée dans quelques instants.</Typography.Text>
              </div>
            )}
        </Col>
        <Col xs={24} md={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", alignSelf: "center", overflow: "hidden" }}>
          <img src={trophiesImg} className="bottom-left-img" style={{ display: "block", width: "90%" }} alt="Elu service client de l'année 2023" />
        </Col>
      </Row>

      {/* <div>
          {is_correct ? (
            <>
              <Alert description={
                <>
                  <p>Bien joué ! Vous accédez au tirage au sort pour tenter de gagner : <strong>{quiz?.gift}</strong></p>
                </>
              } type="success" message={<strong>Bonne(s) réponse(s) !</strong>} showIcon style={{ marginBottom: "1rem" }} />
            </>
          ) : (
            <>
              <Alert description={
                <>
                  <p>Réessayer une autre fois pour accéder au tirage au sort et tenter de gagner : <strong>{quiz?.gift}</strong></p>
                </>
              } type="error" message={<strong>Mauvaise(s) réponse(s) !</strong>} showIcon style={{ marginBottom: "1rem" }} />
            </>
          )}
        </div> */}
    </div>
  );
};

export default QuizPage;
