import { Col, Radio, RadioChangeEvent, Row } from "antd";
import { useState } from "react";

const ORDERS = ["A", "B", "C", "D", "E", "F", "G"]

const RadioQuestionOptions: React.FC<RadioQuestionOptionsProps> = (props) => {
    const onChange = (e: RadioChangeEvent) => {
        props.onChange(e.target.value)
    };
    return (
        <Radio.Group onChange={onChange}>
            {props?.options?.map((option, i) => (
                <div className="option-wrapper">
                    <Radio key={i} className="text-primary" style={{width:"100%"}} value={option?.id}>
                        <Row style={{width: "100%"}}>
                            <Col span={2}>
                                <span className="order">{ORDERS[i]}</span>
                            </Col>
                            <Col span={22}>
                                <span className="option-content">{option?.content}</span>
                            </Col>
                        </Row>
                    </Radio>
                </div>
            ))}
        </Radio.Group>
    );
}
export default RadioQuestionOptions;