
import React, { useCallback, useState } from 'react';
import { Button, Card, Col, Input, Row, Typography } from 'antd';
import './question-card.scss'
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import CheckboxQuestionOptions from '../checkbox-question/CheckboxQuestionOptions';
import RadioQuestionOptions from '../radio-question/RadioQuestionOptions';
import { RootState } from '../../../store/store';
import { useAppSelector } from '../../../hooks/reduxHooks';

const QuestionCard: React.FC<QuestionCardProps> = (props) => {
    const { isRequesting }: QuizState = useAppSelector(
        (state: RootState) => state.quizReducer
    );
    const [answer, setAnswer] = useState<any>(null);
    const onChangeAnswer = useCallback((ans) => {
        setAnswer(ans)
    }, [])
    const onClickNext = useCallback(() => {
        if (!props?.question)
            return
        props?.onSubmit(props?.question?.id, answer)
    }, [answer, props])
    const renderOptions = useCallback(() => {
        switch (props?.question?.type) {
            case "radio":
                return <RadioQuestionOptions options={props?.options} onChange={onChangeAnswer} />
            case "checkbox":
                return <CheckboxQuestionOptions options={props?.options} onChange={onChangeAnswer} />
            default:
                return <Input placeholder="Saisir votre réponse" value={typeof (answer) == "string" ? answer : ""} />
        }
    }, [props.question, props.options, answer, onChangeAnswer])
    return (
        <div className='question-card'>
            <div className="question-header">
                <Card bordered={false}>
                    <Typography.Title level={1} className='question' style={{ margin: 0, fontSize: 24, fontWeight: 400 }}>
                        <div dangerouslySetInnerHTML={{ __html: props?.question?.content || "" }}></div>
                    </Typography.Title>
                </Card>
            </div>
            <div className='question-body'>
                {renderOptions()}
            </div>

            <Button type="primary" loading={isRequesting} className="btn-primary" onClick={onClickNext}>
                Suivant
            </Button>

        </div>
    );
}
export default QuestionCard;