import axiosInstance from '../../../config/axios-instance'

export function getActiveQuizRequest() {
    return axiosInstance({
        method: "get",
        url: "/auth/quiz",
    });
}
export function submitQuizAnswersRequest(quiz_id: number, answers: any) {
    return axiosInstance({
        method: "post",
        url: "/auth/quiz/submit",
        data: { quiz_id, answers }
    });
}
