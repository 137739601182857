import axios from "axios";
import { LANG } from "../store/reducers/ui-reducer/types";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 20000,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    },
});

export const setAuthToken = async (config: any) => {
    try {
        var token = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-token" : "token");
        if (token) {
            //applying token
            config.headers.Authorization = `Bearer ${token}`
        } else {
            //deleting the token from header
            delete config.headers.Authorization;
        }
    } catch (error) {
        // saving error
    }

}

const responseHandler = (response: any) => {
    return response;
};

const errorHandler = async (error: any) => {
    try {
        var token = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-token" : "token");
        if (token && error?.response?.status == 401) {
            // store.dispatch({ type: SESSION_TIMEOUT });
            console.log(error.response);

        } else {
            return Promise.reject(error);
        }
    } catch (error) {
        return Promise.reject(error);
    }

};

// Add a request interceptor
axiosInstance.interceptors.request.use(async function (config) {
    // const defaultLang: LANG | string = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY + "-lang" : "lang") || "en";
    // if (config.url != "/passwordless")
    //     config.params = { ...config.params, lang: defaultLang }
    // Do something before request is sent
    await setAuthToken(config);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
    async (response) => await responseHandler(response),
    async (error) => await errorHandler(error)
);

axiosInstance.defaults.withCredentials = true;

export default axiosInstance;