import { Reducer, AnyAction } from "redux";
import * as actionTypes from "../../actions/quiz-actions/types"

const INITIAL_STATE = {
  quiz: null,
  questions: [],
  isLoading: false,
  isRequesting: false,
  isAuthenticated: false,
  has_completed_quiz: false,
  is_correct: false,
};

const authReducer = (
  state: QuizState = INITIAL_STATE,
  action: QuizAction
): QuizState => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_QUIZ_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_ACTIVE_QUIZ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        quiz: action?.payload?.quiz,
        questions: action?.payload?.questions
      }
    case actionTypes.GET_ACTIVE_QUIZ_FAILURE:
      return {
        ...state,
        isLoading: false,
        quiz: null,
        questions: []
      }
    case actionTypes.SUBMIT_QUIZ_REQUEST:
      return {
        ...state,
        isRequesting: true,
      }
    case actionTypes.SUBMIT_QUIZ_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        has_completed_quiz: action?.payload?.has_completed_quiz || false,
        is_correct: action?.payload?.is_correct || false,
      }
    case actionTypes.SUBMIT_QUIZ_FAILURE:
      return {
        ...state,
        isRequesting: false,
        has_completed_quiz: false,
        is_correct: false,
      }
    case actionTypes.RESET_QUIZ:
      return INITIAL_STATE

  }
  return state
};

export default authReducer;