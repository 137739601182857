import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, children } : any) {
  useEffect(() => {
    const body = document.querySelector('body');
    history.listen(() => {
      if(body) body.scrollTo(0, 0);
    });
  }, [history]);
  
  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
