import React from 'react';
import { Layout } from 'antd';
import { RouteComponentProps, RouteProps } from 'react-router-dom';
import "./layouts.scss"
const { Header, Content, Footer, Sider } = Layout;

const MainLayout = ({ location, children, ...rest }: RouteProps) => {
    return (
        <Layout className={"app-container"}>
            <Layout>
                <Content className='main-content'>
                    {children}
                    <Footer style={{ textAlign: 'center', color: "#fff", fontSize: "16px", padding: ".5rem", width: "100%" }}>Quiz Amen Bank ©{(new Date()).getFullYear()}<span hidden> Powered by <a href="https://www.ulysse.media" target="_blank" rel="noopener noreferrer" className='ulysse'>Ulysse.</a></span></Footer>
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;